/* Provide sufficient contrast against white background */
html,body{
    height:100%;
}
body{
    background-color: deepskyblue;
    color: #000000;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
header {
    background-color: #ffffff;
}
.card {
    border: none;
    background-color: #ffffff;
}
.card-body {
    border-radius: 25px !important;
    background-color: #ffffff;
}
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
.curp, .dropdown-item {
    cursor: pointer
}
.dbfr{
    display:block;
    float:right;
}
.pmaxW {
    max-width: 57%;
    min-width: 57%;
    float: left;
}
.dilblock{
    display:flow-root
}
.css-1q67y5k{
    max-height:unset !important;
}
.descriptionText{
    font-size: 14px;
}
.bold-text{
    font-weight: 600;
}
.listName{
    margin-bottom: 0.6rem;
}
.dinFlex {
    display: -webkit-inline-box;
    width: 100%;
    overflow-x: auto;
    overflow-y:hidden;
    height: calc(100% - 120px);
    position: absolute;
}
.clsExpired{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);
    z-index:2;
}
.clsBtnExpired {
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 300;
    background-color: rgba(0,0,0,0.6);
    padding:20px;
    color:white;
    text-align:center;
}
.itemListHeight {
    height: calc(100vh - 200px);
}
.loaderM {
    margin: 40px auto;
}
.minh100{
    min-height:150px;
    overflow-x:auto;
}
.modal-header{
    display:block !important;
}
.lh15 {
    position: inherit;
    left: 0px;
    top: 4px;
}
.badgeStyle {
    position: relative;
    bottom: 6px;
    left: 2px;
    border-radius: 50%;
}